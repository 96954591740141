import { URL, blogPostPageUrl, buildRootUrl, storyPageUrl } from '@/common/app/utils/urlsUtils';

import { ReviewWithURL } from '../domain/ActivityPage.domain';

import {
    DaysInfoRates,
    Schedule,
    TAvailabilityFull,
    TAvailabilityRates,
    TRate,
    Ticket,
} from '@/common/service/api/Availability/Availability.domain';
import { TConfig } from '@/common/service/api/Config/Config.domain';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { TSelected, TStatus } from '../../../common/app/contexts/ActivityContext';
import { TBreadcrumbItems } from '@/shared/Breadcrumbs/domain/Breadcrumbs.domain';
import { TPageContent } from '@/entities/Content/ui/Content.types';

const authorName = 'TripShock!';

export const createActivityReviews = (activity: IActivity): ReviewWithURL[] => {
    if (!activity.reviews.length) {
        return [];
    }

    return activity.reviews.slice(0, 4).map((review) => ({
        ...review,
        url: `/${activity.slug}/details/${activity.id}/reviews/${review.id}/`,
    }));
};

export const createActivityPageStructuredData = (activity: IActivity): string => {
    const category = activity.activity_categories[0] || {};

    const defaultReview = {
        '@type': 'Review',
        'reviewBody': '',
        'reviewRating': {
            '@type': 'Rating',
            'ratingValue': 0,
        },
        'author': {
            '@type': 'Person',
            'name': '',
        },
    };

    const isActivityRating =
        activity && typeof activity.rating !== 'undefined' && Number(activity.rating) > 0;

    const currentDate = new Date();

    currentDate.setFullYear(currentDate.getFullYear() + 1);
    const currentFormattedDate = currentDate.toISOString().substring(0, 10);

    const reviews = activity?.reviews
        ? activity.reviews.map((review) => {
              return {
                  '@type': 'Review',
                  'reviewBody': review.content,
                  'reviewRating': {
                      '@type': 'Rating',
                      'ratingValue': review.rate,
                  },
                  'author': {
                      '@type': 'Person',
                      'name': review.user,
                  },
              };
          })
        : defaultReview;

    return (
        activity &&
        category &&
        JSON.stringify({
            '@context': 'http://www.schema.org',
            '@type': 'Product',
            'productID': activity.id,
            'sku': activity.id,
            'mpn': activity.id,
            'brand': {
                name: 'TripShock',
            },
            'logo': `${URL.image}/activity/${activity.id}/${activity.slug}.jpg`,
            'name': activity.name,
            'category': category.name || '',
            'image': `${URL.image}/activity/${activity.id}/${activity.slug}.jpg`,
            'url': buildRootUrl(activity.url),
            'description': activity.previewDescription,
            ...(isActivityRating
                ? {
                      aggregateRating: {
                          '@type': 'AggregateRating',
                          'ratingValue': activity.rating,
                          'reviewCount': parseInt(activity.rate_count)
                              ? activity.rate_count
                              : parseFloat(activity.rating) > 0
                              ? '1'
                              : '0',
                      },
                  }
                : {}),
            'offers': {
                '@type': 'Offer',
                'url': buildRootUrl(activity.url),
                'priceCurrency': 'USD',
                'price': activity.price ? activity.price : 0,
                'seller': {
                    '@type': 'Organization',
                    'name': 'Tripshock',
                },
                'priceValidUntil': currentFormattedDate,
                'availability': 'https://schema.org/InStock',
            },
            'review': reviews,
        })
    );
};

export const createStructuredStoryData = (story: TPageContent) =>
    story &&
    JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        'mainEntityOfPage': {
            '@type': 'WebPage',
            '@id': buildRootUrl(storyPageUrl({ slug: story.slug })),
        },
        'headline': story.title,
        'image': story.image ? `${URL.image}/story/${story.image.id}/${story.image.slug}.jpg` : '',
        'datePublished': story.created_at,
        'dateModified': story.updated_at,
        'author': {
            '@type': 'Organization',
            'name': authorName,
        },
        'publisher': {
            '@type': 'Organization',
            'name': authorName,
            'logo': {
                '@type': 'ImageObject',
                'url': 'https://www.tripshock.com/img/ts-logo-for-structured-data.png',
            },
        },
        'description': story.description,
    });

export const createStructuredBlogData = (blog: TPageContent) =>
    blog &&
    JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Article',
        'mainEntityOfPage': {
            '@type': 'WebPage',
            '@id': buildRootUrl(blogPostPageUrl({ slug: blog.slug })),
        },
        'headline': blog.title,
        'image': blog.image ? `${URL.image}/blog/${blog.image.id}/${blog.image.slug}.jpg` : '',
        'datePublished': blog.created_at_iso,
        'dateModified': blog.updated_at_iso,
        'author': {
            '@type': 'Person',
            'name': blog.author,
            'jobTitle': 'TripShock Staff',
            'url': buildRootUrl(blog.author?.trim().toLowerCase().replace(/\s/g, '-')),
        },
        'publisher': {
            '@type': 'Organization',
            'name': 'TripShock',
            'logo': {
                '@type': 'ImageObject',
                'url': 'https://www.tripshock.com/img/ts-logo-for-structured-data.png',
            },
            'url': 'https://www.tripshock.com/',
        },
        'description': blog.description,
    });

export const createStructuredBreadcrumbList = (breadcrumbs: TBreadcrumbItems = []) => {
    const itemListElement = breadcrumbs.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        'position': index + 1,
        'name': breadcrumb.title,
        'item': buildRootUrl(breadcrumb.url),
    }));

    if (!itemListElement.length) {
        return null;
    }

    return JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': itemListElement,
    });
};

export const findMinMaxPrices = (days: DaysInfoRates[]): number[] => {
    const prices: number[] = days.reduce((res, day) => {
        if (day.is_avail) {
            res.push(day.min_price);
        }
        return res;
    }, [] as number[]);

    prices.sort((a, b) => a - b);

    return [prices[0], prices[prices.length - 1]];
};

export const selectedData = (avail: TAvailabilityRates, selected: TSelected) => {
    const selectedDay = avail.days_info?.find((availItem) => availItem.id === selected.calendar_id);
    const selectedRate = selectedDay?.rates.find(
        (rateItem) => rateItem.rate_id === selected.rate_id
    );
    const selectedSchedule = selectedRate?.schedules?.find(
        (schedule) => schedule.id === selected.schedule_id
    );

    return { selectedDay, selectedRate, selectedSchedule };
};

export const checkScheduleSoldout = (schedule?: Schedule, rate?: TRate) => {
    if (schedule?.sold_out) {
        const availSchedule = rate?.schedules?.find((sched) => !sched.sold_out);

        return availSchedule ? availSchedule.id : 0;
    }
    return schedule?.id || 0;
};

export const getAvailRateId = (currentRateId: number, currentDay: DaysInfoRates) => {
    return (
        currentDay.rates.find((rate) => rate.rate_id === currentRateId && rate.is_avail)?.rate_id ||
        currentDay.rates.find((rate) => rate.is_avail)?.rate_id ||
        0
    );
};

/* eslint-disable sonarjs/cognitive-complexity */
export const validateAddToCart = (
    activity: IActivity,
    selected: TSelected,
    availability: TAvailabilityRates,
    status: TStatus
): {
    isValid: boolean;
    error: boolean;
    message: string;
    link: string;
} => {
    /*  const needTicketsGuests = Boolean(+activity.need_tickets_guests); */
    const { selectedRate, selectedSchedule } = selectedData(availability, selected);

    const additionalOptions = selectedRate?.additional_options;
    const selectedScheduleTickets = selected.tickets;

    if (!selectedSchedule) {
        return {
            isValid: false,
            message: 'Please select a schedule.',
            error: true,
            link: 'availability',
        };
    }

    if (Number(selectedSchedule?.min_tickets) > 1) {
        const totalSelectedCount = selectedSchedule?.tickets.reduce(
            (res, ticket) => res + (selected.tickets[ticket.id] || 0),
            0
        );
        if (totalSelectedCount > 0 && totalSelectedCount < Number(selectedSchedule.min_tickets)) {
            return {
                isValid: false,
                message: `You need at least ${selectedSchedule.min_tickets} ticket(s)`,
                error: true,
                link: 'min-tickets',
            };
        }
    }

    if (selectedSchedule.tickets.length > 0) {
        if (selectedSchedule.tickets.every((ticket) => !selectedScheduleTickets[ticket.id])) {
            return {
                isValid: false,
                message: 'Please select the ticket type.',
                error: true,
                link: 'tickets',
            };
        }

        selectedSchedule.tickets.forEach((ticket) => {
            const min = parseInt(ticket.min);
            const sel = selectedScheduleTickets[ticket.id] || 0;
            if (min > sel && !selectedScheduleTickets[ticket.id]) {
                return {
                    isValid: false,
                    message: `Ticket ${ticket.name} contains an invalid value.`,
                    error: true,
                    link: 'tickets',
                };
            } else {
                return;
            }
        });

        if (
            !selectedSchedule.tickets.some(
                (ticket) => selected.tickets[ticket.id] && ticket.is_child === '0'
            )
        ) {
            return {
                isValid: false,
                message: 'You Cannot Order Child Tickets Only',
                error: true,
                link: 'is-child',
            };
        }

        if (+activity.need_tickets_guests) {
            const guestsIsNotValid = selectedSchedule.tickets.some((ticket) => {
                if (!selected.tickets[ticket.id]) return false;
                const guestFields = selected.guests[ticket.id];
                if (!guestFields || Object.keys(guestFields).length < selected.tickets[ticket.id]) {
                    return true;
                }
                return Object.values(guestFields).some(
                    (guest) => !guest.first_name || !guest.last_name
                );
            });
            if (guestsIsNotValid) {
                return {
                    isValid: false,
                    message: 'Please enter the guest(s) names.',
                    error: true,
                    link: 'guest-name',
                };
            }
        }
    }

    if (additionalOptions && additionalOptions.length > 0) {
        const filteredOption = additionalOptions.filter(
            (additionalOption) => !+additionalOption.is_disabled
        );

        for (const additionalOption of filteredOption) {
            const isRequired = Boolean(+additionalOption.required);
            const specifiedValue = selected.additional_options[+additionalOption.id];
            if (isRequired && !specifiedValue) {
                const isContainOptionWord = /.+option$/i.test(additionalOption.name);
                return {
                    isValid: false,
                    message: `Please specify ${additionalOption.name}${
                        !isContainOptionWord ? ' option' : ''
                    }.`,
                    error: true,
                    link: 'additional_options',
                };
            }
        }
    }

    // TODO add total price calculation

    if (status.totalPrice <= 0) {
        throw new Error('Tickets total amount must be greater than $0.00');
    }

    if (activity.use_tripflex === '1' && selected.add_tripflex < 0) {
        return {
            isValid: false,
            message: 'Please select a cancellation policy.',
            error: true,
            link: 'cancellation-info',
        };
    }

    if (!selected.agreement_confirm) {
        return {
            isValid: false,
            message: 'Please agree to the reservation requirements.',
            error: true,
            link: 'cancellation-info',
        };
    }

    return {
        isValid: true,
        message: '',
        error: false,
        link: '',
    };
};

export const validateRate = (
    activity: IActivity,
    selected: TSelected,
    availability: TAvailabilityRates
): boolean => {
    /*  const needTicketsGuests = Boolean(+activity.need_tickets_guests); */
    const { selectedRate, selectedSchedule } = selectedData(availability, selected);

    const additionalOptions = selectedRate?.additional_options;
    const selectedScheduleTickets = selected.tickets;

    if (!selectedSchedule) {
        return false;
    }

    if (Number(selectedSchedule?.min_tickets) > 1) {
        const totalSelectedCount = selectedSchedule?.tickets.reduce(
            (res, ticket) => res + (selected.tickets[ticket.id] || 0),
            0
        );
        if (totalSelectedCount > 0 && totalSelectedCount < Number(selectedSchedule.min_tickets)) {
            return false;
        }
    }

    if (selectedSchedule.tickets.length > 0) {
        if (selectedSchedule.tickets.every((ticket) => !selectedScheduleTickets[ticket.id])) {
            return false;
        }

        selectedSchedule.tickets.forEach((ticket) => {
            const min = parseInt(ticket.min);
            const sel = selectedScheduleTickets[ticket.id] || 0;
            if (min > sel && !selectedScheduleTickets[ticket.id]) {
                return false;
            } else {
                return;
            }
        });

        if (
            !selectedSchedule.tickets.some(
                (ticket) => selected.tickets[ticket.id] && ticket.is_child === '0'
            )
        ) {
            return false;
        }

        if (+activity.need_tickets_guests) {
            const guestsIsNotValid = selectedSchedule.tickets.some((ticket) => {
                if (!selected.tickets[ticket.id]) return false;
                const guestFields = selected.guests[ticket.id];
                if (!guestFields || Object.keys(guestFields).length < selected.tickets[ticket.id]) {
                    return true;
                }
                return Object.values(guestFields).some(
                    (guest) => !guest.first_name || !guest.last_name
                );
            });
            if (guestsIsNotValid) {
                return false;
            }
        }
    }

    if (additionalOptions && additionalOptions.length > 0) {
        const filteredOption = additionalOptions.filter(
            (additionalOption) => !+additionalOption.is_disabled
        );

        for (const additionalOption of filteredOption) {
            const isRequired = Boolean(+additionalOption.required);
            const specifiedValue = selected.additional_options[+additionalOption.id];
            if (isRequired && !specifiedValue) {
                return false;
            }
        }
    }

    return true;
};

export const createTripflexValue = (
    tickets: Ticket[],
    selectedTickets: { [key: number]: number },
    config: TConfig
): { value: number; text: string } => {
    const value_1 = config ? `${config.tripflex_total_entries}`.slice(0, -3) : '';
    const value_2 = config ? `${config.tripflex_total_entries}`.slice(-3) : '';

    const text = `Over ${value_1},${value_2} travelers have purchased this coverage`;

    if (!tickets.length || Object.keys(selectedTickets).length === 0) {
        return {
            value: 0,
            text,
        };
    }
    let totalAmountOfTickets = 0;
    tickets.map((ticket) => {
        if (selectedTickets[ticket.id] !== undefined) {
            totalAmountOfTickets += ticket.price * selectedTickets[ticket.id];
        }
    });

    if (totalAmountOfTickets === 0) {
        return {
            value: 0,
            text,
        };
    }

    if (+config.tripflex_max_item_amount < totalAmountOfTickets) {
        return {
            value: -1,
            text,
        };
    }

    const basic_price = (totalAmountOfTickets * +config.tripflex_target) / 100;
    let result = 0;

    if (basic_price < +config.tripflex_min) {
        result = +config.tripflex_min;
    } else {
        result = Math.floor(basic_price) - 0.05;
        if (result > +config.tripflex_max) {
            result = +config.tripflex_max;
        }
    }

    return {
        value: result,
        text,
    };
};

export const getAvailScheduleID = (
    scheduleID: number,
    availability: TAvailabilityRates,
    calendarID: string,
    rateID?: number
) => {
    const selectedDay = availability.days_info.find((availItem) => availItem.id === calendarID);
    const selectedRate = rateID
        ? selectedDay?.rates.find((rateItem) => rateItem.rate_id === rateID)
        : selectedDay?.rates[0];
    const selectedSchedule = selectedRate?.schedules?.find(
        (schedule) => schedule.id === scheduleID
    );

    if (!selectedSchedule || selectedSchedule?.sold_out) {
        const availSchedule = selectedRate?.schedules?.find((schedule) => !schedule.sold_out);

        if (availSchedule) {
            return availSchedule.id;
        } else {
            return 0;
        }
    }

    return scheduleID;
};

const moveSoldoutRatesToEnd = (days: DaysInfoRates[]) =>
    days.map((day) => ({ ...day, rates: day.rates.sort((a, b) => +b.is_avail - +a.is_avail) }));

export const ratesMapper = <T>(
    avail: T extends TAvailabilityRates ? TAvailabilityRates : TAvailabilityFull
): TAvailabilityRates => {
    if ('use_rates' in avail) {
        const sorted_days_info = moveSoldoutRatesToEnd(avail.days_info);

        return { ...avail, days_info: sorted_days_info } as TAvailabilityRates;
    } else {
        const newDaysInfo = avail.days_info.map(({ schedules, additional_options, ...rest }) => {
            return {
                ...rest,
                rates: [
                    {
                        rate_id: 0,
                        rate_name: '',
                        schedules,
                        additional_options,
                        is_avail: true,
                        tickets_avail: 0,
                    },
                ],
            };
        });

        return {
            days_info: newDaysInfo,
            use_calendar: avail.use_calendar,
            use_schedule: avail.use_schedule,
            use_rates: '0' as const,
        };
    }
};

// TODO it's just a test func we should remove it before deploy to prod
export const addTestDataForRates = (avail: TAvailabilityRates) => {
    const names = [
        'Alligator watching tours',
        'Alligator feeding shows',
        'Mini golf with alligators',
        'Alligator wrestling',
        'Parasailing over alligator farm',
        'Alligator safari',
        'Selfie with alligator',
        'Ride on the alligator',
        'Obstacle course on the alligator farm',
        'Fight with alligator',
        1,
    ];

    const getRandomNumber = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    avail.days_info.forEach((day) => {
        const rate = day.rates[0];

        day.rates = Array(getRandomNumber(1, 10))
            .fill(rate)
            .map((item, index) => ({ ...item, rate_name: names[index], rate_id: index + 1 }));
    });
};
