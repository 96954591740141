import { memo, ReactElement, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import * as S from './Footer.styles';
import { useMedia } from '@/common/service/hooks/useMedia';
import { COMPANY_LIST, DESTINATIONS_LIST, SUPPORT_LIST, WORK_WITH_US_LIST } from '../app/Data';

import { DropdownLink } from '@/common/ui/inputs/DropdownLink';
import { Link } from '@/common/ui';
import { FooterBlockProps } from './FooterBlock/FooterBlock.domain';
import SocialIcon from '@/shared/SocialIcons/SocialIcons';

const FooterBlock = dynamic<FooterBlockProps>(
    () => import('@/shared/Footer/ui/FooterBlock').then((module) => module.FooterBlock),
    { ssr: false }
);

const currentYear = new Date().getFullYear();

const FooterContainer = (): ReactElement => {
    const isDesktop = useMedia('(min-width: 992px)');

    const [isActive, setIsActive] = useState(isDesktop ? 0 : 3);

    const toggle = useCallback(
        (id: number) => () => {
            if (id === isActive) {
                setIsActive(0);
            } else {
                setIsActive(id);
            }
        },
        [isActive]
    );

    return (
        <S.Footer data-test-id="Footer">
            <S.Container>
                <div>
                    <S.Title data-test-id="header">Destinations</S.Title>
                    <DropdownLink list={DESTINATIONS_LIST} title="Choose your destination" />
                    <a
                        key="NavPhoneNumber"
                        href="tel: 850-424-5125"
                        className="phone-number"
                        data-test-id="phone-number"
                    >
                        <S.PhoneLink>
                            <S.PhoneIcon />
                            850-424-5125
                        </S.PhoneLink>
                    </a>
                    <SocialIcon />
                </div>
                <FooterBlock
                    title="Support"
                    isOpen={isDesktop || isActive === 1}
                    toggle={toggle(1)}
                    list={SUPPORT_LIST}
                />
                <FooterBlock
                    title="Work with us"
                    isOpen={isDesktop || isActive === 2}
                    toggle={toggle(2)}
                    list={WORK_WITH_US_LIST}
                />
                <FooterBlock
                    title="Company"
                    isOpen={isDesktop || isActive === 3}
                    toggle={toggle(3)}
                    list={COMPANY_LIST}
                />
                <S.CopyrightContainer>
                    <Link href="/" passHref>
                        <a aria-label="footer logo" data-testid="logo">
                            <S.Logo />
                        </a>
                    </Link>
                    <S.Sign>
                        {`© ${currentYear}  `}
                        <S.SmallArrow />
                        <S.ColoredSign>Tripshock</S.ColoredSign>
                    </S.Sign>
                </S.CopyrightContainer>
            </S.Container>
        </S.Footer>
    );
};

export const Footer = memo(FooterContainer);
