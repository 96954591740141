import { TTheme } from '@/styles/Themes/Theme.domain';
import styled, { ThemedStyledProps } from 'styled-components';
import { TSocialIconStyled } from './SocialIcon.types';

export const StyledSocialIcon = styled.div<ThemedStyledProps<TSocialIconStyled, TTheme>>`
    display: flex;
    margin-bottom: 32px;
`;

export const StyledSocialLink = styled.a`
    margin-right: 24px;
`;
