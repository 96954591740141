import { memo, ReactElement } from 'react';
import { StyledSocialIcon, StyledSocialLink } from './SocialIcon.styles';

const SocialIconComponent = (): ReactElement => {
    return (
        <StyledSocialIcon data-testid="socialIcons">
            <StyledSocialLink
                data-testid="facebook-icon"
                href="https://www.facebook.com/tripshockofficial"
            >
                <img
                    height={24}
                    width={24}
                    alt="Facebook"
                    src="/social/facebook.svg"
                    loading="lazy"
                />
            </StyledSocialLink>
            {/* <StyledSocialLink data-testid="x-twitter-icon" href="https://x.com/tripshock">
                <img
                    height={24}
                    width={24}
                    alt="X-Twitter"
                    src="/social/x-twitter.svg"
                    loading="lazy"
                />
            </StyledSocialLink> */}
            <StyledSocialLink data-testid="youtube-icon" href="https://www.youtube.com/c/Tripshock">
                <img
                    height={24}
                    width={24}
                    alt="Youtube"
                    src="/social/youtube.svg"
                    loading="lazy"
                />
            </StyledSocialLink>
            <StyledSocialLink
                data-testid="tiktok-icon"
                href="https://www.tiktok.com/@tripshock_official"
            >
                <img height={22} width={22} alt="Tiktok" src="/social/tiktok.svg" loading="lazy" />
            </StyledSocialLink>
            <StyledSocialLink
                data-testid="instagram-icon"
                href="https://www.instagram.com/tripshock/"
            >
                <img
                    height={24}
                    width={24}
                    alt="Instagram"
                    src="/social/instagram.svg"
                    loading="lazy"
                />
            </StyledSocialLink>
        </StyledSocialIcon>
    );
};

const SocialIcon = memo(SocialIconComponent);
export { SocialIcon };
export default SocialIcon;
