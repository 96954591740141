import { ChangeEventHandler, Dispatch, SetStateAction, useState } from 'react';
import * as S from './DiscountWidget.styles';
import { Button } from '@/common/ui';
import { getLS, LocalStorageTypes, setLS } from '@/common/service/storage';
import { trackSubscribed } from '@/common/app/utils/iterable';

export const DiscountWidgetForm = ({
    setSubmitted,
}: {
    setSubmitted: Dispatch<SetStateAction<boolean>>;
}) => {
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.target;
        if (name === 'firstName') setFirstName(value);
        if (name === 'email') setEmail(value);
    };

    const handleSubmit = async () => {
        const contactInfo = getLS(LocalStorageTypes.LS_CONTACT_INFO) || { firstName, email };

        trackSubscribed({ email }).then((res) => {
            if (res.status === 200) {
                setLS(LocalStorageTypes.LS_CONTACT_INFO, { ...contactInfo, firstName, email });
                setSubmitted(true);
            }
        });
    };

    return (
        <>
            <S.FormHeader>
                <S.FormTitle>
                    Plan Ahead & Save Up to 20% with Exclusive Savings From TripShock
                </S.FormTitle>
                <S.FormList>
                    <S.FormListItem>5% OFF (7 days in advance)</S.FormListItem>
                    <S.FormListItem>10% OFF (14 days in advance)</S.FormListItem>
                    <S.FormListItem>15% OFF ( 30 days in advance)</S.FormListItem>
                    <S.FormListItem>20% OFF ( 45 days in advance)</S.FormListItem>
                </S.FormList>
                <S.Description>
                    Fill out the form below to receive promo codes to apply to your order!
                </S.Description>
            </S.FormHeader>

            <S.Form onSubmit={() => handleSubmit}>
                <S.FormInput
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    placeholder="First Name:"
                    onChange={handleChange}
                    required
                />
                <S.FormInput
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Email:"
                    onChange={handleChange}
                    required
                />
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </S.Form>
        </>
    );
};
